import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  selected(id, where, route, orWhere=[]) {
    var params = {      
      where: where,
      orWhere: orWhere,
    }    
    var url = ConfigAPI.baseURL + route.urlShowAPI + "/" + id + Session.getToken();
    return instance.post(url,params);
  },  
  filter(filter, where, route, orWhere=[]) {
    var params = {
      filter: filter, 
      where: where,
      orWhere: orWhere,
    }
    var url = ConfigAPI.baseURL + route.urlFilterAPI + Session.getToken();
    return instance.post(url,params);
  },
}

export default servicesAPI;
